<template>
  <AffiliateLayout>
    <b-card
      class="referrals-block p-2"
      no-body
      title="Thiết lập referrals"
    >
      <div class="one-item align-items-center">
        <div class="label">
          Số cấp muốn tạo
        </div>
        <div class="one-content w-100px">
          <b-input-group class="input-group-merge">
            <b-form-input
              placeholder="Nhập số"
              type="number"
            />
          </b-input-group>
        </div>
      </div>
      <div class="line" />
      <div class="one-item">
        <div class="label">
          Thưởng giới thiệu
        </div>
        <div class="one-content">
          <div class="one-row">
            <div class="w-100px title">
              Level
            </div>
            <div class="w-225px title">
              Số tiền
            </div>
            <div class="w-225px title">
              Thêm quà
            </div>
          </div>
          <div class="one-row">
            <div class="w-100px title">
              Level 0
            </div>
            <div class="w-225px title">
              <b-input-group
                append="VNĐ"
                class="input-group-merge"
              >
                <b-form-input
                  type="number"
                  placeholder="Nhập số tiền"
                />
              </b-input-group>
            </div>
            <div class="w-225px title">
              <v-select
                v-model="SelectedA"
                :options="optionsH"
                label="name"
                placeholder="Chọn quà tặng"
              />
            </div>
          </div>
          <div class="one-row">
            <div class="w-100px title">
              Level 1
            </div>
            <div class="w-225px title">
              <b-input-group
                append="VNĐ"
                class="input-group-merge"
              >
                <b-form-input
                  type="number"
                  placeholder="Nhập số tiền"
                />
              </b-input-group>
            </div>
            <div class="w-225px title">
              <v-select
                v-model="SelectedA"
                :options="optionsH"
                label="name"
                placeholder="Chọn quà tặng"
              />
            </div>
          </div>
          <div class="one-row">
            <div class="w-100px title">
              Level 2
            </div>
            <div class="w-225px title">
              <b-input-group
                append="VNĐ"
                class="input-group-merge"
              >
                <b-form-input
                  type="number"
                  placeholder="Nhập số tiền"
                />
              </b-input-group>
            </div>
            <div class="w-225px title">
              <v-select
                v-model="SelectedA"
                :options="optionsH"
                label="name"
                placeholder="Chọn quà tặng"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="line" />
      <div class="one-item">
        <div class="label">
          Thưởng trên đơn hàng
        </div>
        <div class="one-content">
          <div class="one-row">
            <div class="w-100px title">
              Level
            </div>
            <div class="w-225px title">
              Tỷ lệ %
            </div>
          </div>
          <div class="one-row">
            <div class="w-100px title">
              Level 1
            </div>
            <div class="w-225px title">
              <b-input-group
                append="%"
                class="input-group-merge"
              >
                <b-form-input
                  type="number"
                  placeholder="Nhập phần trăm"
                />
              </b-input-group>
              <div class="title-sub text-center">
                Level 0
              </div>
            </div>

          </div>
          <div class="one-row">
            <div class="w-100px title">
              Level 2
            </div>
            <div class="w-225px title">
              <b-input-group
                append="%"
                class="input-group-merge"
              >
                <b-form-input
                  type="number"
                  placeholder="Nhập phần trăm"
                />
              </b-input-group>
              <div class="title-sub text-center">
                Level 1
              </div>
            </div>
            <div class="w-225px title">
              <b-input-group
                append="%"
                class="input-group-merge"
              >
                <b-form-input
                  type="number"
                  placeholder="Nhập phần trăm"
                />
              </b-input-group>
              <div class="title-sub text-center">
                Level 0
              </div>
            </div>
          </div>
          <div class="one-row">
            <div class="w-100px title">
              Level 3
            </div>
            <div class="w-225px title">
              <b-input-group
                append="%"
                class="input-group-merge"
              >
                <b-form-input
                  type="number"
                  placeholder="Nhập phần trăm"
                />
              </b-input-group>
              <div class="title-sub text-center">
                Level 2
              </div>
            </div>
            <div class="w-225px title">
              <b-input-group
                append="%"
                class="input-group-merge"
              >
                <b-form-input
                  type="number"
                  placeholder="Nhập phần trăm"
                />
              </b-input-group>
              <div class="title-sub text-center">
                Level 1
              </div>
            </div>
            <div class="w-225px title">
              <b-input-group
                append="%"
                class="input-group-merge"
              >
                <b-form-input
                  type="number"
                  placeholder="Nhập phần trăm"
                />
              </b-input-group>
              <div class="title-sub text-center">
                Level 0
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-ctrl">
        <b-button
          variant="outline-primary"
          class="btn"
        >
          Lưu thay đổi
        </b-button>
      </div>
    </b-card>
  </AffiliateLayout>
</template>

<script>
import {
  // BFormGroup,
  BCard,
  BInputGroup,
  BFormInput,
  BButton,
  // BFormSelect,
} from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'
import MixinAffiliate from '../mixin'
import AffiliateLayout from '../layouts/AffiliateLayout.vue'

Vue.mixin(MixinAffiliate)

export default {
  components: {
    // BFormGroup,
    BCard,
    BInputGroup,
    BFormInput,
    BButton,
    // BFormSelect,
    vSelect,
    AffiliateLayout,
  },
  data() {
    return {
      SelectedA: null,
      optionsH: [
        { id: 'c', name: '100.000đ' },
        { id: 'a', name: 'Mã freeship' },
        { id: 'b', name: '100 IFS xu' },
      ],
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.w-100px{
  width: 100px;
}
.w-225px{
  width: 225px;
}
.one-item{
  display: flex;
}
.label{
  width: 250px;
  color: #7367f0;
  font-weight: bold;
  font-size: 16px;
}
.line{
  height: 1px;
  width: 100%;
  background-color: #3E444B;
  opacity: 0.12;
}
.one-content{
  display: flex;
  gap: 16px;
  flex-direction: column;

  .one-row{
    display: flex;
    align-items: center;
    gap: 40px;
  }
  .title{
    font-size: 15px;
    color: #7367f0;
    font-weight: bold;
  }
}
.referrals-block{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.btn-ctrl{
  text-align: left;
  .btn{
    background-color: #7367f0;
    color: #fff;
  }
}
</style>
